import { Component, Input } from '@angular/core';

import { TagDto } from '@data-import/data-access/bulk-operations-api';

@Component({
  selector: 'di-tag-labels',
  templateUrl: './tag-labels.component.html',
  styleUrls: ['./tag-labels.component.scss'],
})
export class TagLabelsComponent {
  @Input() items: TagDto[];
  @Input() clear;
  @Input() isDisabled?: boolean;
}
