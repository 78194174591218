import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { LeftMenuService } from '../../../../core/services/left-menu.service';
import { MenuStep } from './step.model';

@Component({
  selector: 'di-menu-stepper',
  templateUrl: './menu-stepper.component.html',
  styleUrls: ['./menu-stepper.component.scss'],
})
export class MenuStepperComponent implements OnInit {
  stepperProperties$: Observable<MenuStep[]>;
  showBackButton$: Observable<boolean>;

  constructor(private leftMenuService: LeftMenuService) {}

  ngOnInit(): void {
    this.stepperProperties$ = this.leftMenuService.getStepperProperties();
    this.showBackButton$ = this.leftMenuService.getBackButtonStatus();
  }
}
