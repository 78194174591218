import { Injectable } from '@angular/core';

import { ITimeline } from '@data-import/data-access/bulk-operations-api';

@Injectable({
  providedIn: 'root',
})
export class TimelineService {
  getActiveTimelineItem<T extends ITimeline>(
    timeline: T[],
    activeTime: Date = new Date(),
  ): T | undefined {
    if (!timeline) {
      return undefined;
    }

    // Hack to fix utc time
    const activeDate = new Date(
      Date.UTC(activeTime.getFullYear(), activeTime.getMonth(), activeTime.getDate()),
    );

    // https://github.com/visma-net/employee-public-api-docs/blob/main/FAQ.md
    const activeItem = timeline.find((item: T) => {
      return (
        (!item.activeStart || activeDate >= item.activeStart) &&
        (!item.activeEnd || activeDate <= item.activeEnd)
      );
    });

    if (!activeItem) {
      return undefined;
    }

    return activeItem;
  }

  getIsHistoryItemsAvailable(timeline: ITimeline[]): boolean {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return timeline.some((v) => v.activeEnd && v.activeEnd < today);
  }

  getOrderedTimeline<T extends ITimeline>(timeline: T[]): T[] {
    const orderedTimeline = [...timeline];
    return orderedTimeline.sort(this.timelineItemComparer);
  }

  private getDayBefore(date: Date): Date {
    return date ? new Date(date.getTime() - 24 * 60 * 60 * 1000) : undefined;
  }

  private getDayAfter(date: Date): Date {
    return date ? new Date(date.getTime() + 24 * 60 * 60 * 1000) : undefined;
  }

  private timelineItemComparer(a: ITimeline, b: ITimeline): number {
    if (!a.activeStart) {
      return !b.activeStart ? 0 : -1;
    } else {
      if (!b.activeStart) {
        return 1;
      } else {
        return a.activeStart < b.activeStart ? -1 : a.activeStart > b.activeStart ? 1 : 0;
      }
    }
  }

  getUniquePeriodsForTimelines<T extends ITimeline>(
    timelines: T[],
    positionStart: Date,
    positionEnd: Date,
  ) {
    const sortedDates = this.getUniqueSortedDates(timelines, positionStart);

    if (positionStart && sortedDates[0].getTime() > positionStart.getTime()) {
      sortedDates.unshift(positionStart);
    }
    if (positionEnd && sortedDates[sortedDates.length - 1].getTime() < positionEnd.getTime()) {
      sortedDates.push(this.getDayAfter(positionEnd));
    }

    const mergedDays = [];
    sortedDates.forEach((date, i) => {
      const lastItemIndex = sortedDates.length - 1;

      if (i < lastItemIndex) {
        mergedDays.push({ activeStart: date, activeEnd: this.getDayBefore(sortedDates[i + 1]) });
      } else if (!positionEnd) {
        mergedDays.push({ activeStart: date, activeEnd: positionEnd });
      }
    });

    return mergedDays;
  }

  getUniqueSortedDates<T extends ITimeline>(timelines: T[], positionStart: Date): Date[] {
    let dates = [];
    timelines.forEach((timeline) => {
      const activeStart = timeline?.activeStart || positionStart;
      const activeEnd = timeline?.activeEnd ? this.getDayAfter(timeline.activeEnd) : undefined;
      dates.push(activeStart, activeEnd);
    });
    dates = dates.filter((date) => date);
    const uniqueDates = [...new Set(dates.map((date) => date?.getTime()))].map(
      (unixTime: number) => new Date(unixTime),
    );
    return uniqueDates.sort((a, b) => a.getTime() - b.getTime());
  }
}
