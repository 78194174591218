<div
  class="d-flex flex-column cursor-pointer sorting-wrapper"
  data-testId="sorting-wrapper"
  (click)="setOrder(sortedByEnum.Unsorted, $event)"
>
  <span
    class="arrow-up mr-4"
    data-testId="dsc-order-arrow"
    [ngClass]="{ invisible: isDscArrowHidden && isActive }"
    (click)="setOrder(sortedByEnum.Dsc)"
  ></span>
  <span
    class="arrow-down mr-4"
    data-testId="asc-order-arrow"
    [ngClass]="{ invisible: isAscArrowHidden && isActive }"
    (click)="setOrder(sortedByEnum.Asc)"
  ></span>
</div>
