import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'di-grid-status-row',
  templateUrl: './grid-status-row.component.html',
  styleUrls: ['./grid-status-row.component.scss'],
})
export class GridStatusRowComponent {
  @Input() employeesCount: number;
  @Input() isErrorAlert: boolean;
  @Input() shownOnlyGeneralMessage: boolean;
  @Output() showData = new EventEmitter();
  isAllDataShowing = true;

  onShowDataClick() {
    this.isAllDataShowing = !this.isAllDataShowing;
    this.showData.emit();
  }
}
