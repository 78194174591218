import { APP_BASE_HREF } from '@angular/common';
import { Inject, Injectable, Optional, isDevMode } from '@angular/core';
import { LDClient, initialize } from 'launchdarkly-js-client-sdk';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { LD_CLIENT_SIDE_ID } from '../feature-flag.module';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private ldClient: LDClient;

  constructor(
    @Optional() @Inject(LD_CLIENT_SIDE_ID) clientSideId?: string,
    @Optional() @Inject(APP_BASE_HREF) appBaseHref?: string,
  ) {
    const odpOrgId = appBaseHref?.replace(/\D/g, '');

    if (isDevMode() && !clientSideId) {
      clientSideId = 'non-empty-id';
    }
    this.ldClient = initialize(clientSideId, { kind: 'org', key: odpOrgId ?? 'Default' });
  }

  getFlagValue(flagName: string, defaultValue = false): Observable<boolean> {
    return from(this.ldClient.waitUntilReady()).pipe(
      map(() => this.ldClient.variation(flagName, defaultValue)),
    );
  }
}
