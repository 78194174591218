import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { VudDropdownModule, VudTooltipModule } from '@vismaux/ngx-vud';

import { NavbarBrandOptionComponent } from './navbar-brand-option/navbar-brand-option.component';
import { NavbarBrandComponent } from './navbar-brand/navbar-brand.component';
import { NavbarMenuItemComponent } from './navbar-menu-item/navbar-menu-item.component';
import { NavbarMenuComponent } from './navbar-menu/navbar-menu.component';
import { NavbarRightMenuComponent } from './navbar-right-menu/navbar-right-menu.component';
import { NavbarSecondaryMenuComponent } from './navbar-secondary-menu/navbar-secondary-menu.component';
import { NavbarComponent } from './navbar.component';
import { NavbarConfig, VUD_NAVBAR_CONFIG } from './navbar.config';
import { NavbarService } from './navbar.service';

@NgModule({
  imports: [CommonModule, VudDropdownModule, CdkMenuModule, VudTooltipModule],
  declarations: [
    NavbarComponent,
    NavbarBrandComponent,
    NavbarBrandOptionComponent,
    NavbarMenuComponent,
    NavbarMenuItemComponent,
    NavbarRightMenuComponent,
    NavbarSecondaryMenuComponent,
  ],
  exports: [
    VudDropdownModule,
    NavbarComponent,
    NavbarBrandComponent,
    NavbarBrandOptionComponent,
    NavbarMenuComponent,
    NavbarMenuItemComponent,
    NavbarRightMenuComponent,
    NavbarSecondaryMenuComponent,
  ],
  providers: [NavbarService],
})
export class NavbarModule {
  static withConfig(config: NavbarConfig): ModuleWithProviders<NavbarModule> {
    return {
      ngModule: NavbarModule,
      providers: [{ provide: VUD_NAVBAR_CONFIG, useValue: config }],
    };
  }
}
