import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  QueryList,
  inject,
} from '@angular/core';

import { NavbarBrandOptionComponent } from './navbar-brand-option/navbar-brand-option.component';
import { NavbarService } from './navbar.service';

export type VudNavbarType = 'default' | 'secondary';

@Component({
  selector: 'di-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'vudNavbar',
})
export class NavbarComponent {
  /** @ignore */
  private readonly navbarService = inject(NavbarService);

  @Input() type: VudNavbarType = 'default';

  readonly isBurgerMenuBpMatched$ = this.navbarService.isBurgerMenuBpMatched$;

  /**
   * @ignore
   * @deprecated use your own breakpoint system to handle the use of this utility breakpoint
   */
  readonly isTablet$ = this.navbarService.isTablet$;

  /** @ignore */
  @ContentChildren(NavbarBrandOptionComponent, { descendants: true })
  readonly brandOptions?: QueryList<NavbarBrandOptionComponent>;

  /** @ignore */
  isBurgerMenuBpMatched() {
    return this.navbarService.isBurgerMenuBpMatched();
  }
}
