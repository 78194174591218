import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  QueryList,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NavbarMenuItemComponent } from '../navbar-menu-item/navbar-menu-item.component';

@Component({
  selector: 'di-navbar-secondary-menu',
  templateUrl: './navbar-secondary-menu.component.html',
  styleUrls: ['./navbar-secondary-menu.component.scss'],
})
export class NavbarSecondaryMenuComponent implements AfterViewInit {
  private _active = new BehaviorSubject(false);
  private readonly destroyed$ = new Subject<void>();
  hiddenMenuItems: boolean[] = [];

  @Input() set active(value: boolean) {
    this._active.next(value);
  }
  @Input() label = '';
  @Input() href?: string;

  @ContentChildren(NavbarMenuItemComponent)
  readonly menuItems!: QueryList<NavbarMenuItemComponent>;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.rerenderMenuItems();

    // Re-render menu items whenever projection changes
    this.menuItems.changes
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.rerenderMenuItems());
  }

  rerenderMenuItems(): void {
    // Reset cached widths
    this.hiddenMenuItems = [];

    // Needed for ngTemplateOutlet
    this.cdRef.detectChanges();

    // Must wait for the menu items to be painted so that the menu can properly
    // calculate the correct visibility based on the size of the item content.
    Promise.resolve().then(() => {
      // this.cacheMenuWidths();
      // this.checkResponsiveness();
    });
  }
}
