import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  NgZone,
  OnDestroy,
  QueryList,
  inject,
} from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { NavbarMenuItemComponent } from '../navbar-menu-item/navbar-menu-item.component';
import { NavbarComponent } from '../navbar.component';

const MORE_ITEM_WIDTH = 100;

@Component({
  selector: 'di-navbar-menu',
  templateUrl: './navbar-menu.component.html',
  styleUrls: ['./navbar-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarMenuComponent implements OnDestroy, AfterViewInit {
  public readonly navbar = inject(NavbarComponent, { host: true });
  private readonly elRef: ElementRef<HTMLElement> = inject(ElementRef);
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly ngZone = inject(NgZone);

  private readonly resized$ = new Subject<void>();
  private readonly destroyed$ = new Subject<void>();
  private resizeObserver: ResizeObserver;
  private menuItemsWidths: number[] = [];

  readonly moreMenuPosition: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    },
  ];

  hiddenMenuItems: boolean[] = [];
  brandOptionsOpen = false;
  moreMenuOpen = false;

  @ContentChildren(NavbarMenuItemComponent)
  readonly menuItems!: QueryList<NavbarMenuItemComponent>;

  get anyHiddenMenuItem() {
    return this.hiddenMenuItems.some((i) => i);
  }

  get activeBrandOption() {
    // Removed non-null assertion as it is forbidden by lint
    return this.navbar.brandOptions.find((n) => n.active);
  }

  ngAfterViewInit(): void {
    this.rerenderMenuItems();

    // Re-render menu items whenever projection changes
    this.menuItems.changes
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.rerenderMenuItems());

    // Re-render menu items in case styles completes to load AFTER the initialization of this menu.
    // Ref issue: #28
    fromEvent(window, 'load')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.rerenderMenuItems());

    // Attach resize observer if available,
    // otherwise user will have to use `checkResponsiveness` manually.
    if (typeof window !== 'undefined' && window.ResizeObserver) {
      this.resizeObserver = new window.ResizeObserver(() => this.resized$.next());
      this.resizeObserver.observe(this.elRef.nativeElement);
      this.resized$.pipe(debounceTime(100), takeUntil(this.destroyed$)).subscribe(() => {
        this.ngZone.run(() => this.checkResponsiveness());
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.elRef.nativeElement);
    }
  }

  checkResponsiveness(): void {
    if (this.navbar.isBurgerMenuBpMatched()) {
      return;
    }

    let changed = false;
    // Check if everything fits well
    const availableWidth = this.elRef.nativeElement.offsetWidth;
    const totalMenuItemsWidth = this.menuItemsWidths.reduce((a, b) => a + b, 0);
    let takenWidth = totalMenuItemsWidth;

    if (availableWidth > takenWidth) {
      this.menuItems.forEach((_, i) => {
        if (this.hiddenMenuItems[i]) {
          this.hiddenMenuItems[i] = false;
          changed = true;
        }
      });
    } else {
      takenWidth = MORE_ITEM_WIDTH;

      // Go trough menu items and add ones that fit
      this.menuItems.forEach((_, i) => {
        const totalTaken = takenWidth + this.menuItemsWidths[i];
        const doesNotFit = totalTaken > availableWidth;
        if (this.hiddenMenuItems[i] !== doesNotFit) {
          this.hiddenMenuItems[i] = doesNotFit;
          changed = true;
        }
        if (!doesNotFit) {
          takenWidth += this.menuItemsWidths[i];
        }
      });
    }

    if (changed) {
      this.cdRef.detectChanges();
    }
  }

  rerenderMenuItems(): void {
    // Reset cached widths
    this.menuItemsWidths = [];
    this.hiddenMenuItems = [];

    // Needed for ngTemplateOutlet
    this.cdRef.detectChanges();

    // Must wait for the menu items to be painted so that the menu can properly
    // calculate the correct visibility based on the size of the item content.
    Promise.resolve().then(() => {
      this.cacheMenuWidths();
      this.checkResponsiveness();
    });
  }

  toggleBrandOptions(): void {
    this.brandOptionsOpen = !this.brandOptionsOpen;
    this.cdRef.detectChanges();
  }

  private cacheMenuWidths(): void {
    const items = this.elRef.nativeElement.querySelectorAll<HTMLElement>(
      'ul.first-level > li.virtual > a.nav-item',
    );
    this.menuItemsWidths = Array.from(items).map((e) => e.offsetWidth);
  }
}
