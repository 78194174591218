import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[diDragAndDropFileSelector]',
})
export class DragAndDropFileSelectorDirective {
  @HostBinding('class.file-over') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<File>();

  @HostListener('dragover', ['$event'])
  onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.isValid(evt)) this.fileOver = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event'])
  onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    if (this.isValid(evt)) {
      this.fileDropped.emit(evt.dataTransfer.files[0]);
    }
  }

  private isValid(event) {
    if (event.dataTransfer.items.length !== 1) return false;

    const file = event.dataTransfer.items[0];

    return file.kind === 'file';
  }
}
