import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'di-grid-card',
  templateUrl: './grid-card.component.html',
  styleUrls: ['./grid-card.component.scss'],
})
export class GridCardComponent {
  @Input() iconClass: string;
  @Input() isExternalLink: boolean;
  @Input() isCardDisabled: boolean;
  @Output() clickCard = new EventEmitter();
}
