import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { LeftMenuService } from '../../../core/services/left-menu.service';

@Component({
  selector: 'di-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
})
@UntilDestroy()
export class LeftMenuComponent {
  isMenuCollapsed: boolean;

  constructor(private leftMenuService: LeftMenuService) {
    this.leftMenuService
      .getIsMenuCollapsed()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.isMenuCollapsed = value;
      });
  }

  toggleMenu(): void {
    this.leftMenuService.toggleMenuCollapse(!this.isMenuCollapsed);
  }
}
