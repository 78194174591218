import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'di-table-status-col',
  templateUrl: './table-status-col.component.html',
  styleUrls: ['./table-status-col.component.scss'],
})
export class TableStatusColComponent {
  @Input() hasWarning: boolean;
  @Input() hasError: boolean;
  @Input() isSuccessful: boolean;
  @Input() isInProgress: boolean;
  @Input() hasCheckbox: boolean;
  @Input() checkboxId: string;
  @Input() isCheckboxChecked: boolean;
  @Output() toggleCheckboxEmitter = new EventEmitter();

  toggleCheckbox(): void {
    this.toggleCheckboxEmitter.emit();
  }
}
