import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Context } from '@datadog/browser-core';
import { datadogLogs } from '@datadog/browser-logs';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  CallCorrelationIdHeaderName,
  SessionCorrelationIdHeaderName,
  vismaCallId,
  vismaSessionId,
} from '@data-import/data-access/bulk-operations-api';

@Injectable()
export class CorrelationIdInterceptor implements HttpInterceptor {
  private router: Router;

  constructor(injector: Injector) {
    this.router = injector.get(Router);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const callId = vismaCallId();
    const sessionId = vismaSessionId();
    request = request.clone({
      headers: request.headers
        .set(CallCorrelationIdHeaderName, callId)
        .set(SessionCorrelationIdHeaderName, sessionId),
    });

    return next.handle(request).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          if (err.status >= 400) {
            const message = `XHR error ${err.status} ${request.method} ${err.url}`;
            const context: Context = {
              callId: callId,
              http: {
                method: request.method,
                status_code: err.status,
                status_text: err.statusText,
                url: err.url,
                message: err.message,
                error: err.error,
              },
            };

            if (err.status >= 400 && err.status < 500) {
              datadogLogs.logger.warn(message, context, err);
            } else {
              datadogLogs.logger.error(message, context, err);
            }
          }
        },
      }),
    );
  }
}
