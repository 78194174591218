<ul class="second-level" role="menu" aria-label="">
  @for (item of menuItems; track item; let i = $index) {
    @if (!item.isHidden) {
      <li
        [class.active]="item.isActive$ | async"
        [class.d-inline-flex]="item.isDisabled"
        [class.is-open]="item.isActive$ | async"
      >
        <a
          class="nav-item d-flex align-items-center"
          role="menuitem"
          [attr.aria-current]="item.isActive$ | async"
          [attr.href]="item.href"
          [ngClass]="{ 'disabled-state pr-8': item.isDisabled }"
          (click)="item.click.emit($event)"
        >
          <ng-container [ngTemplateOutlet]="item.content" />
          @if (item.iconClass) {
            <span [class]="item.iconClass"></span>
          }
        </a>
        @if (item.isDisabled) {
          <span
            class="vismaicon vismaicon-filled vismaicon-sm vismaicon-info align-self-center"
            [vudTooltip]="item.tooltipText"
          ></span>
        }
      </li>
    }
  }
</ul>
