import { Component } from '@angular/core';
import { TaskStatusEnum } from '@bo-schema-api-dto';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'di-grid-error-row-renderer',
  templateUrl: './grid-error-row-renderer.component.html',
  styleUrls: ['./grid-error-row-renderer.component.scss'],
})
export class GridErrorRowRendererComponent implements ICellRendererAngularComp {
  errorMessage: string;
  params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;

    this.setErrorMessage();
  }

  refresh(): boolean {
    this.setErrorMessage();

    return false;
  }

  private setErrorMessage(): void {
    this.errorMessage = this.params.data.error;

    if (!this.errorMessage && this.params.data.status === TaskStatusEnum.PollingTimeout) {
      this.errorMessage = $localize`Timed out waiting for a response. Please check the changes manually or contact support`;
    }
  }
}
