import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { SortedByEnum } from '../../shared/components/sort-items/sorted-by.enum';

@Injectable({
  providedIn: 'root',
})
export class EmployeesListService {
  sortOrder: SortedByEnum;
  sortValue: string;
  _activeSortValueSubj = new BehaviorSubject<string>('employeeName');
  activeSortValue$ = this._activeSortValueSubj.asObservable();

  updateActiveSortValue(value: string): void {
    this._activeSortValueSubj.next(value);
  }
}
