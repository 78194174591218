import { Pipe, PipeTransform } from '@angular/core';

import { EnumLocalizationHelper } from './enum-localization.helper';

@Pipe({
  name: 'localizedEnum',
})
export class LocalizedEnumPipe implements PipeTransform {
  transform(value: EnumTypes, enumType: StringEnumTypes): string {
    const translatorFnName: string = 'get' + enumType.replace('Enum', '');
    const translatorFn = EnumLocalizationHelper[translatorFnName];

    if (translatorFn) {
      return translatorFn(value);
    } else {
      console.warn(`There is no EnumLocalizationHelper.${translatorFnName} function.`);
      return value;
    }
  }
}

type EnumTypes = {
  [K in keyof typeof EnumLocalizationHelper]: (typeof EnumLocalizationHelper)[K] extends (
    ...args: infer P
  ) => unknown
    ? P[0]
    : never;
}[keyof typeof EnumLocalizationHelper];

type StringEnumTypes = keyof {
  [K in keyof typeof EnumLocalizationHelper as K extends `get${infer R}`
    ? `${R}Enum`
    : never]: (typeof EnumLocalizationHelper)[K];
};
