<div class="navbar-header">
  <div class="navbar-brand dropdown" [class.open]="menuOpen">
    <a
      id="dropdown-toggle"
      href="#"
      role="button"
      draggable="false"
      [cdkMenuTriggerFor]="menu"
      [class.dropdown-toggle]="navbar.brandOptions!.length > 0"
      (cdkMenuClosed)="menuOpen = false"
      (cdkMenuOpened)="menuOpen = true"
      (click)="$event.preventDefault()"
    >
      <ng-content />
      <span class="caret"></span>
    </a>
  </div>
</div>

<ng-template #menu>
  <ul class="dropdown-menu navbar-brand-menu overflow-y-auto" cdkMenu>
    @for (brandOption of navbar.brandOptions; track brandOption) {
      <li role="none">
        <a
          cdkMenuItem
          [attr.aria-current]="brandOption.active"
          [attr.aria-label]="brandOption.label"
          [attr.href]="brandOption.href"
          [class.active]="brandOption.active"
          (click)="brandOption.click.emit($event)"
        >
          <ng-template [ngTemplateOutlet]="brandOption.content" />
        </a>
      </li>
    }
  </ul>
</ng-template>
