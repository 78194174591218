@for (item of items; track item) {
  <div class="tag-item">
    <span class="tag-color" [style.color]="item.color">
      <svg height="10" width="10">
        <circle cx="5" cy="5" fill="currentColor" r="5" />
      </svg>
    </span>
    <span class="tag-label">{{ item.name }}</span>
    @if (!isDisabled) {
      <span class="tag-action ng-value-icon" (click)="clear(item)">x</span>
    }
  </div>
}
