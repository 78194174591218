import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { WootricProperties } from '../../shared/directives/wootric.directive';
import { UserSessionService } from './user-session.service';

@Injectable({
  providedIn: 'root',
})
export class WootricService {
  constructor(private userSessionService: UserSessionService) {
    this.userSessionService
      .getSession()
      .pipe(take(1))
      .subscribe((userSession) => {
        // window['wootric_survey_immediately'] = true; //Overwrites sampling settings and forces the server to return true to all survey requests.  TODO: Comment out for production.
        // window['wootric_no_surveyed_cookie'] = true; //Disables the cookie writing after a survey is taken effectively disabling any client side mechanisms to prevent multiple surveys from being rendered.  TODO: Comment out for production.
        window['wootricSettings'] = {
          email: userSession.emailAddress,
          created_at: Math.floor(Date.now() / 1000),
          account_token: environment.wootricAccountToken,
        };
      });
  }

  addWootric(wootricEventName: string, wootricProperties?: WootricProperties) {
    window['wootricSettings'].event_name = wootricEventName; //Input() values shouldnt be used inside constructor because of async
    window['wootricSettings'].properties = wootricProperties; //Input() values shouldnt be used inside constructor because of async
    if (document.getElementById('wootric-el')) {
      window['WootricSurvey']?.run();
      return;
    }
    if (environment.wootricAccountToken) {
      const script = document.createElement('script');
      script.id = 'wootric-el';
      script.type = 'text/javascript';
      script.src = 'https://cdn.wootric.com/wootric-sdk.js';
      script.async = true;
      script.onload = function () {
        window['WootricSurvey']?.run();
      };

      document.querySelector('body').appendChild(script);
    }
  }
}
