<div
  class="vertical-nav pt-8 vertical-nav-icons"
  data-testId="vertical-nav"
  [ngClass]="{
    'hidden-menu': isMenuCollapsed,
  }"
>
  <nav class="h-100" aria-label="Navigation">
    <ul class="nav">
      <ng-content />
      <li class="show-nav">
        <a data-testId="menu-toggle" (click)="toggleMenu()">Show/Hide Menu</a>
      </li>
    </ul>
  </nav>
</div>
