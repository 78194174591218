import { Component } from '@angular/core';
import { VudModalComponent } from '@vismaux/ngx-vud';

@Component({
  selector: 'di-tags-error-modal',
  templateUrl: './tags-error-modal.component.html',
  styleUrls: ['./tags-error-modal.component.scss'],
})
export class TagsErrorModalComponent extends VudModalComponent<void, boolean> {
  closeModal(value?): void {
    this.close(value);
  }
}
