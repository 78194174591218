<div class="modal-header">
  <h4 class="modal-title" i18n>Something went wrong</h4>
</div>
<div class="modal-body">
  <span i18n> We couldn't load tags at this moment. Please try again or contact Support if the problem persists. </span>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="button" (click)="closeModal(true)" i18n> Try again </button>
  <button class="btn" type="button" routerLink="/" (click)="closeModal()" i18n> Back to employees </button>
</div>
