import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { getLocalePreference } from '../../../../localization.helper';
import { EmployeesListService } from '../../../core/services/employees-list.service';
import { LanguagesEnum } from '../../localizers/languages.enum';
import { SortedByEnum } from './sorted-by.enum';

@Component({
  selector: 'di-sort-items',
  templateUrl: './sort-items.component.html',
  styleUrls: ['./sort-items.component.scss'],
})
@UntilDestroy()
export class SortItemsComponent implements OnChanges, OnInit {
  @Input() itemsToSort;
  @Input() sortingValue;
  @Input() defaultValue: string;
  isActive: boolean;
  @Output() setSortedValue = new EventEmitter<string>();
  isAscArrowHidden: boolean;
  isDscArrowHidden: boolean;
  previousSortingOrder: string;
  sortedBy = SortedByEnum.Asc;
  sortedByEnum = SortedByEnum;
  stringValues = [
    'name',
    'employeeName',
    'oldContractSalaryType',
    'newContractSalaryType',
    'salaryType',
    'employmentForm',
  ];
  localizedStringValues = ['paycodeName', 'oldTimeAgreementName'];
  employeeListValues = [
    'employeeNumber',
    'employeeName',
    'value.employeeName',
    'value.employeeNumber',
  ];

  constructor(private employeesListService: EmployeesListService) {}

  setOrder(order: SortedByEnum, event?: MouseEvent): void {
    if (order) {
      this.previousSortingOrder = order === SortedByEnum.Asc ? SortedByEnum.Dsc : SortedByEnum.Asc;
      return;
    }
    if (!this.previousSortingOrder && event) {
      order = event.offsetY < 28 ? SortedByEnum.Dsc : SortedByEnum.Asc;
    } else {
      order = this.previousSortingOrder === SortedByEnum.Asc ? SortedByEnum.Dsc : SortedByEnum.Asc;
    }
    this.previousSortingOrder = order;
    this.resetSorting();
    this.employeesListService.updateActiveSortValue(this.sortingValue);
    if (this.employeeListValues.includes(this.sortingValue)) {
      this.employeesListService.sortValue = this.getProperSortingValue();
      this.employeesListService.sortOrder = order;
    }
    this.doSorting(order);
    this.sortedBy = order;
    this.setArrowVisibility();
  }

  ngOnInit(): void {
    this.setActiveItem();
    this.sortActiveItem();
  }

  ngOnChanges(): void {
    this.sortActiveItem();
  }

  private sortActiveItem(): void {
    if (this.isActive) {
      this.employeesListService.sortValue ? this.sortByPreviousValue() : this.sortByCurrentValue();
    }
  }

  private sortByPreviousValue(): void {
    if (this.sortingValue.includes(this.employeesListService.sortValue)) {
      this.sortedBy = this.employeesListService.sortOrder;
      this.setArrowVisibility();
      this.doSorting(this.employeesListService.sortOrder);
    }
  }

  private sortByCurrentValue(): void {
    this.setArrowVisibility();
    this.doSorting(this.sortedBy);
  }

  private resetSorting(): void {
    this.isAscArrowHidden = false;
    this.isDscArrowHidden = false;
  }

  private setArrowVisibility(): void {
    this.sortedBy === SortedByEnum.Asc
      ? (this.isAscArrowHidden = true)
      : (this.isDscArrowHidden = true);
  }

  private setActiveItem(): void {
    const savedValue = this.employeesListService.sortValue;
    const sortValue =
      this.defaultValue && savedValue
        ? `value.${savedValue}`
        : savedValue || this.defaultValue || 'employeeName';
    this.employeesListService.updateActiveSortValue(sortValue);
    this.employeesListService.activeSortValue$
      .pipe(untilDestroyed(this))
      .subscribe((value) => (this.isActive = this.sortingValue === value));
  }

  private getProperSortingValue(): string {
    switch (this.sortingValue) {
      case 'value.employeeName':
        return 'employeeName';
      case 'value.employeeNumber':
        return 'employeeNumber';
      default:
        return this.sortingValue;
    }
  }

  private doSorting(order: SortedByEnum): void {
    const sortingParameters = this.sortingValue.split('.');
    this.itemsToSort.sort((leftVal, rightVal) => {
      let currentSortingProperty;
      sortingParameters.forEach((sortingProperty) => {
        currentSortingProperty = sortingProperty;
        if (
          currentSortingProperty === 'employeeName' &&
          leftVal[currentSortingProperty] === rightVal[currentSortingProperty]
        ) {
          currentSortingProperty = 'paycodeIdentifier';
        }
        if (this.localizedStringValues.includes(currentSortingProperty)) {
          const userLanguage = getLocalePreference();
          const localizedStringLanguage =
            userLanguage === LanguagesEnum.No ? 'norwegian' : 'english';
          leftVal = leftVal[currentSortingProperty]?.[localizedStringLanguage];
          rightVal = rightVal[currentSortingProperty]?.[localizedStringLanguage];
        } else {
          leftVal = leftVal[currentSortingProperty];
          rightVal = rightVal[currentSortingProperty];
        }
      });

      if (
        this.stringValues.includes(currentSortingProperty) ||
        this.localizedStringValues.includes(currentSortingProperty)
      ) {
        return order === SortedByEnum.Asc
          ? leftVal?.localeCompare(rightVal)
          : rightVal?.localeCompare(leftVal);
      } else {
        return order === SortedByEnum.Asc ? leftVal - rightVal : rightVal - leftVal;
      }
    });
  }
}
