<div class="bg-aggrid rounded-top d-flex justify-content-end pr-8 p-relative">
  @if (changedSearchInput.observed) {
    <div class="form-group search-group mb-0 align-self-center mr-8">
      <label class="sr-only" for="search_input">Search</label>
      <input
        class="form-control"
        id="search_input"
        type="search"
        aria-label="searchBlock"
        placeholder="Search"
        (input)="changedSearchInput.emit($any($event.target).value)"
        i18n-placeholder
      />
      <span class="search-icon">Search icon</span>
      <span class="clear-search">Clear</span>
    </div>
  }
  <button class="btn btn-icon btn-transparent btn-square my-4 mr-0" type="button" (click)="autosizeColumns.emit()">
    <span class="vismaicon vismaicon-change-width grid-icon" title="Autosize all columns" i18n-title></span>
  </button>
  <button class="btn btn-icon btn-transparent btn-square my-4 mr-0" type="button" (click)="getExcel.emit()">
    <span class="vismaicon vismaicon-excel grid-icon" title="Download Excel file" i18n-title></span>
  </button>
  <button class="btn btn-icon btn-transparent btn-square my-4 mr-0" type="button" (click)="getCsv.emit()">
    <span class="vismaicon vismaicon-csv grid-icon" title="Download CSV file" i18n-title></span>
  </button>
  <button
    class="btn btn-icon btn-transparent btn-square my-4 mr-0"
    id="settings-btn"
    type="button"
    (click)="isDropdownOpen = !isDropdownOpen"
  >
    <span class="vismaicon vismaicon-multiselection grid-icon rotate-90" title="Grid settings" i18n-title></span>
  </button>
  <div>
    <div class="dropdown p-absolute" [ngClass]="{ open: isDropdownOpen }">
      <ul class="dropdown-menu" role="menu" aria-expanded="false" aria-hidden="true" aria-labelledby="dropdownButton1">
        <li><a (click)="resetColumns.emit()" i18n>Reset columns</a></li>
        <li><a (click)="clearFilters.emit()" i18n>Clear filters</a></li>
        @if (isFloatingFilterPresent) {
          <li><a (click)="onFilterButtonClick()" i18n>Hide floating filter</a></li>
        } @else {
          <li><a (click)="onFilterButtonClick()" i18n>Show floating filter</a></li>
        }
      </ul>
    </div>
  </div>
</div>
