<div>
  <span i18n>
    Processed
    <span class="font-weight-bold">{{ succeededTaskCount + failedTaskCount }}</span>
    out of <span class="font-weight-bold">{{ totalTaskCount }}</span> items.</span
  >
  @if (isProcessed) {
    <span class="text-success ml-4" data-testId="success-text" i18n>
      <b>{{ succeededTaskCount }}</b> successful,
    </span>
  }
  @if (isProcessed) {
    <span class="text-error ml-4" data-testId="error-text" i18n>
      <b>{{ failedTaskCount }}</b>
      errors
    </span>
  }
  @if (!isProcessed) {
    <span i18n> Please be patient, this could take a few minutes... </span>
  }
</div>
