import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable, inject } from '@angular/core';
import { map } from 'rxjs/operators';

import { VUD_DEFAULT_NAVBAR_CONFIG, VUD_NAVBAR_CONFIG } from './navbar.config';

@Injectable()
export class NavbarService {
  private readonly navbarConfig = inject(VUD_NAVBAR_CONFIG);
  private readonly breakpointObserver = inject(BreakpointObserver);

  readonly isBurgerMenuBpMatched$ = this.breakpointObserver
    .observe(`(max-width: ${this.burgerMenuBreakpoint})`)
    .pipe(map(({ matches }) => matches));

  readonly isTablet$ = this.breakpointObserver
    .observe('(max-width: 992px)')
    .pipe(map(({ matches }) => matches));

  isBurgerMenuBpMatched() {
    return this.breakpointObserver.isMatched(`(max-width: ${this.burgerMenuBreakpoint})`);
  }

  get burgerMenuBreakpoint() {
    return this.navbarConfig.burgerMenuBreakpoint ?? VUD_DEFAULT_NAVBAR_CONFIG.burgerMenuBreakpoint;
  }
}
