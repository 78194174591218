import { Directive, HostListener, Input } from '@angular/core';

import { WootricService } from '../../core/services/wootric.service';

export interface WootricProperties {
  updatedEmployees_amount: number;
  failedEmployees_amount: number;
  totalEmployees_amount: number;
}

@Directive({
  selector: '[diWootric]',
})
export class WootricDirective {
  @Input() wootricEventName: string;
  @Input() properties: WootricProperties;

  constructor(private wootricService: WootricService) {}

  @HostListener('click')
  onClick() {
    this.wootricService.addWootric(this.wootricEventName, this.properties);
  }
}
