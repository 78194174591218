import { InjectionToken } from '@angular/core';

export interface NavbarConfig {
  /**
   * Determines the breakpoint for the burger menu.
   *
   * @default `768px`
   */
  burgerMenuBreakpoint?: string;
}

export const VUD_DEFAULT_NAVBAR_CONFIG: NavbarConfig = {
  burgerMenuBreakpoint: '768px',
};

/** InjectionToken for navbar that can be used to override default options. */
export const VUD_NAVBAR_CONFIG = new InjectionToken<NavbarConfig>('VUD_NAVBAR_CONFIG', {
  providedIn: 'root',
  factory: () => VUD_DEFAULT_NAVBAR_CONFIG,
});
