import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { FeatureFlagService } from '../services/feature-flag.service';

@Directive({
  selector: '[diIsFeatureDisabled]',
})
@UntilDestroy()
export class IsFeatureDisabledDirective implements OnInit {
  @Input('diIsFeatureDisabled') flagName!: string;
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private featureFlagService: FeatureFlagService,
  ) {}

  ngOnInit(): void {
    this.featureFlagService
      .getFlagValue(this.flagName)
      .pipe(untilDestroyed(this))
      .subscribe((toggleStatus) => this.updateView(toggleStatus === false));
  }

  private updateView(isHide: boolean) {
    if (isHide && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!isHide && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
