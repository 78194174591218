import { Component, Input } from '@angular/core';

@Component({
  selector: 'di-employees-list-btn',
  templateUrl: './employees-list-btn.component.html',
  styleUrls: ['./employees-list-btn.component.scss'],
})
export class EmployeesListBtnComponent {
  @Input() disabled: boolean;
}
