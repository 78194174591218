import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FeatureFlagService } from '../services/feature-flag.service';

@Injectable({
  providedIn: 'root',
})
export class EnablingFeatureGuard {
  constructor(
    private router: Router,
    private featureToggleService: FeatureFlagService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const flagKey: string = route.data['flagKey'] as string;
    const redirectRoute: string = (route.data['redirectRoute'] as string) || '/';

    return this.featureToggleService
      .getFlagValue(flagKey)
      .pipe(
        map((isEnabled) =>
          isEnabled ? true : redirectRoute && this.router.createUrlTree([redirectRoute]),
        ),
      );
  }

  canLoad(
    route: Route,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const flagKey: string = route.data['flagKey'] as string;
    const redirectRoute: string = (route.data['redirectRoute'] as string) || '/';

    return this.featureToggleService
      .getFlagValue(flagKey)
      .pipe(
        map((isEnabled) =>
          isEnabled ? true : redirectRoute && this.router.createUrlTree([redirectRoute]),
        ),
      );
  }

  canMatch(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const flagKey: string = route.data['flagKey'] as string;
    const redirectRoute: string = (route.data['redirectRoute'] as string) || '/';

    return this.featureToggleService
      .getFlagValue(flagKey)
      .pipe(
        map((isEnabled) =>
          isEnabled ? true : redirectRoute && this.router.createUrlTree([redirectRoute]),
        ),
      );
  }
}
