import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'di-back-to-edit-step-btn',
  templateUrl: './back-to-edit-step-btn.component.html',
  styleUrls: ['./back-to-edit-step-btn.component.scss'],
})
export class BackToEditStepBtnComponent {
  @Input() disabled: boolean;
  @Output() clickOnBack = new EventEmitter<void>();

  goBack(): void {
    this.clickOnBack.emit();
  }
}
