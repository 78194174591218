import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  private router: Router;

  constructor(injector: Injector) {
    this.router = injector.get(Router);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone();

    return next.handle(request).pipe(catchError((err) => this.handleError(err)));
  }

  private handleError(err: HttpErrorResponse): Observable<HttpEvent<unknown>> {
    if ([401, 403, 404].includes(err.status)) {
      this.router.navigateByUrl('/unauthorized');
    }

    return throwError(err);
  }
}
