import { Component, Input } from '@angular/core';

@Component({
  selector: 'di-upload-errors',
  templateUrl: './upload-errors.component.html',
  styleUrls: ['./upload-errors.component.scss'],
})
export class UploadErrorsComponent {
  @Input() errorsList = [];

  getItemName(item: string): string {
    switch (item) {
      case 'id':
        return $localize`Column name - Id`;
      case 'name':
        return $localize`Column name - Name`;
      case 'normalDays':
        return $localize`Column name - Normal Days`;
      case 'transferredDays':
        return $localize`Column name - Transferred Days`;
      case 'additionalDays':
        return $localize`Column name - Additional Days`;
      case 'advancedDays':
        return $localize`Column name - Advanced Days`;
      case 'unpaidDays':
        return $localize`Column name - Unpaid Days`;
      case 'transferredAdditionalDays':
        return $localize`Column name - Transferred Additional Days`;
    }
    return item;
  }
}
